import React from "react";
import { graphql } from "gatsby";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from "../SEO";
import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton } from "react-share"

export default function BlogPost(props) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  const post = props.data.markdownRemark;
  const url = "https://www.skolapparna.se/appar/" + props.path;
  const image = post.frontmatter.coverImage?.childImageSharp.gatsbyImageData;

  return (
    <>
      <SEO
        title={post.frontmatter.title}
        article={true}
        image={image?.images.fallback.src}
      />
      <Navbar />
      <div className="container px-5 py-6 mx-auto">
        <article className="blogPost">
        {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="" />}
          <div>
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-4 mt-2">{post.frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: post.html }} className="blogText" />
            <br />
            <div className="shareButtons">
              <FacebookShareButton url={url} className="share">
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <TwitterShareButton url={url} title={post.frontmatter.title} className="share">
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
            </div>
            <br />
            <br />
          </div>
        </article>
        <br/>
      </div>
      <Footer dark={true} />
    </>
  );
}
export const query = graphql`
  query AppQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        coverImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    },
  }
`;
